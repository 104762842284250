@import "./variables.module";

.navbar-collapse {
  position: absolute !important;
  top: 45px;
  right: 2.5% !important;
  border: 1px solid $contentBorderColor !important;
  text-align: center !important;
  flex-basis: 100% !important;
  flex-grow: 1 !important;
  align-items: center !important;
  z-index: 10000;
  background-color: $borderColor;
  width: 30%;
}

.navbar {
  background-color: $headerBgColor !important;
  // padding: 7px 1rem !important;
  padding: 5px 1rem !important;
  width: 100%;
}

.userDropdown {
  padding-top: 0 !important;

  & > button {
    height: 55px;
    margin-top: -10px;
    margin-bottom: -10px;
    display: flex;
    align-items: center;
    background-color: darken($primaryColor, 15%) !important;
    border: none;
    border-radius: 0;

    &:hover {
      background-color: darken($primaryColor, 18%) !important;
    }

    &:active,
    &:focus {
      background-color: darken($primaryColor, 22%) !important;
    }
    .wel {
      white-space: nowrap !important;
      display: block;
      padding-right: 5px;
    }
  }
}

.userDropdown-menu {
  border-radius: 0;
  padding: 0 2px;
  background-color: lighten($accordionBackground, 15%);
  border: 1px solid #dfe3ea;
  min-width: 200px;
  max-width: 220px;

  .user {
    text-align: center;
    border-bottom: 1px solid #d0d5e0;
    pointer-events: none;
    padding: 0 10px;
    .user-img {
      margin-top: 20px;
      margin-bottom: 5px;
      border-radius: 50%;
      flex: none;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.08);
      vertical-align: middle;
      height: 45px;
      width: 45px;
      object-fit: cover;
      border: 1px solid rgb(197, 197, 197);
    }

    h5 {
      font-weight: bold;
      font-size: 14px;
      font-family: "Raleway";
      background-color: #fff;
      margin-bottom: 15px;
      margin-top: 5px;
      line-height: 1;
    }
    p {
      line-height: 1;
    }

    h6 {
      font-family: "Raleway";
      line-height: 1;
      color: $headerBgColor;
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 400;
      margin-bottom: 12px;
      div {
        margin-bottom: 5px;
        margin-top: 5px;
      }
      * {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .dropdown-item {
    padding: 10px 15px;
    background: transparent;
    border: none;
    border-bottom: 1px solid #eee;
    color: $headerBgColor;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.5px;
    line-height: 1.54;
    min-height: 45px;

    span {
      display: block;
      padding-left: 10px;
    }

    &:hover {
      color: darken($headerBgColor, 10%) !important;
    }

    &:active,
    &:focus,
    &:target {
      background-color: $accordionBackground !important;
    }
  }
}

#hideNavbar {
  display: none;
}

.bigCompanyName {
  font-size: large;
  font-weight: 800;
}

.navbar-brand {
  color: $whiteColor !important;
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
  margin-left: 5px;
}

.customName2 {
  // position: absolute;
  // left: 125px;
  // top: 20%;
  color: $whiteColor;
  font-family: Raleway;
}

.nav-item {
  border-bottom: 0.5px solid $contentBorderColor;
  &:last-child {
    border: none;
  }
}

.navbar-light .navbar-nav .nav-link {
  color: $primaryColor !important;
}

.navbar-light .navbar-nav .nav-link:hover,
.nav-item:hover {
  background-color: $primaryColor !important;
  color: $borderColor !important;
}

.impexNavLogo {
  // padding-left: 1%;
  // width: 110px;
  width: auto;
  height: 43px !important;
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0);
  float: right;
  &:focus,
  &:hover {
    border-color: rgba(0, 0, 0, 0) !important;
    outline: none !important;
  }
}
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.menu-title {
  font-family: "Raleway";
  font-weight: bold;
  font-size: 20px;
  padding-top: -1%;
  color: $whiteColor;
  display: block;
  text-align: center;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 0;
}

.rowWidth {
  width: 100%;
  margin-left: 0;
}

.tree-item-group {
  padding-left: 0;
}
.rstm-toggle-icon {
  display: inline-block;
}
.rstm-toggle-icon-symbol {
  width: 2rem;
  height: 2rem;
  text-align: center;
  line-height: 2rem;
}

.rstm-tree-item-group {
  list-style-type: none;
  padding-left: 0;
  border-top: 1px solid #ccc;
  text-align: left;
  width: 100%;
}

.rstm-tree-item {
  padding: 16px 40px;
  cursor: pointer;
  color: #333;
  background: none;
  border-bottom: 1px solid #ccc;
  box-shadow: none;
  z-index: unset;
  position: relative;

  &:hover {
    background-color: $borderColor !important;
    color: $headerBgColor !important;
  }
}
.rstm-tree-item--active {
  color: white;
}

.rstm-tree-item--focused {
  // box-shadow: 0 0 5px 0 #222;
  z-index: 999;
}

.rstm-search {
  padding: 1rem 1.5rem;
  border: none;
  width: 100%;
}
